@font-face {
  font-family: 'Gotham Book Italic';
  src: url('./lib/fonts/Gotham-Book-Italic.ttf') format('opentype');
}
@font-face {
  font-family: 'Gotham Book';
  src: url('./lib/fonts/Gotham-Book-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('./lib/fonts/Gotham-Bold.otf') format('opentype');
}

:root {
  --primary-color: #19313e;
  /* --secondary-color: #d12f1a; */
  --secondary-color: #ff5157;
  --light-color: #e9ebec;
  --alt-primary-color: #75b8d6;
}
canvas {
  outline: none;
  border: none;
}

body {
  background: rgb(217, 240, 255);
  background: linear-gradient(
    0deg,
    rgba(217, 240, 255, 1) 0%,
    rgba(255, 255, 255, 1) 70%
  );
  margin: 0;
  min-height: 100%;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  font-size: 14px;
}

h1 {
  font-family: 'Gotham Bold', sans-serif;
  font-size: 1.5rem;
}

h2 {
  font-family: 'Gotham Bold', sans-serif;
  font-size: 1.3rem;
}

h3 {
  font-family: 'Gotham Bold', sans-serif;
  font-size: 1.1rem;
}

p,
li {
  font-family: 'Gotham Book', sans-serif;
  font-size: 0.9rem;
  line-height: 1.2rem;
}

.title {
  text-align: left;
  border-bottom: 6px solid var(--secondary-color);
}

.glass {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  -webkit-box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
}

.medium-large {
  font-size: 2.3rem;
}

.medium {
  font-size: 1.7rem;
}

.medium-small {
  font-size: 1.2rem;
}

.small {
  font-size: 1.2rem;
}

.extra-small {
  font-size: 0.7rem;
}

.primaryColor {
  color: var(--primary-color);
}

.secondaryColor {
  color: var(--secondary-color);
}

.alignLeft {
  text-align: left;
}

.paddingRight {
  padding-right: 1rem;
}

.noMarginPadding {
  margin: 0;
  padding: 0;
}

.altParagraph {
  color: var(--primary-color);
  font-size: 1rem;
  line-height: 1.4rem;
  text-align: left !important;
}

.paragraph {
  line-height: 1.4rem;
}

.flex {
  display: flex;
  align-items: baseline;
}

.space-between {
  justify-content: space-between;
}

.bold {
  font-family: 'Gotham Bold', sans-serif;
}

.noPadding {
  padding: 0;
}

.subtitle {
  font-family: 'Gotham Book Italic', sans-serif;
  font-size: 0.8rem;
  line-height: 1rem;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0.5rem;
  letter-spacing: 0.4px;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 12px;
    box-sizing: border-box;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
